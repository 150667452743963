import Divider from '@mui/material/Divider';
import './VenueComponent.css';
import VenuePhotoURL1 from '../../Assets/Venue/Venue1.jpeg'
import VenuePhotoURL2 from '../../Assets/Venue/Venue2.jpeg'
import VenuePhotoURL3 from '../../Assets/Venue/Venue3.jpeg'
import VenuePhotoURL4 from '../../Assets/Venue/Venue4.jpeg'
import VenuePhotoURL5 from '../../Assets/Venue/Venue5.jpeg'
import VenuePhotoURL6 from '../../Assets/Venue/Venue6.jpeg'
import { Grid } from '@mui/material';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ChurchIcon from '@mui/icons-material/Church';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import FlatwareIcon from '@mui/icons-material/Flatware';
import GroupsIcon from '@mui/icons-material/Groups';
import Waze from '../../Assets/waze.svg';
import GMaps from '../../Assets/gmaps.svg';
import { HaciendaVistahermosa } from '../Hotels/HotelInformation';

function CarouselCard(props: { img: string }): JSX.Element {
    return (
        <div>
            <img className='photos' alt="Hacienda Vista Hermosa" src={props.img} />
        </div >
    )
}

function Itinerary(props: any): JSX.Element {
    let lang: string;

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }


    return (
        <div>
            <p className='VenueTitle'>{lang.includes("es") ? "Viernes 7" : "Friday 7th "}</p>
            <Grid container justifyContent="center" alignItems="center" style={{ padding: 30 }}>
                <Grid xs={8} className='VenueDescription Itinerary'>
                    <b>{lang.includes("es") ? "Convivio" : "Casual gathering"} <br /></b>
                    TBD <br />
                    7:00 p.m.
                </Grid>
                <Grid xs={4}>
                    <GroupsIcon style={{ fontSize: "9vh" }} />
                </Grid>
            </Grid>
            <Divider variant='fullWidth' style={{ borderWidth: 3, borderColor: 'black', marginTop: 5, marginBottom: 10 }} />
            <p className='VenueTitle'>{lang.includes("es") ? "Sábado 8" : "Saturday 8th "}</p>
            <Grid container justifyContent="center" alignItems="center" style={{ padding: 30 }}>
                <Grid xs={8} className='VenueDescription Itinerary'>
                    <b>{lang.includes("es") ? "Misa" : "Religious Ceremony"} <br /></b>
                    La Troje <br />
                    12:00 p.m.
                </Grid>
                <Grid xs={4}>
                    <ChurchIcon style={{ fontSize: "9vh" }} />
                </Grid>
            </Grid>
            <Divider variant='middle' style={{ borderWidth: 2, borderColor: 'black' }} />
            <Grid container justifyContent="center" alignItems="center" style={{ padding: 30 }}>
                <Grid xs={8} >
                    <div className='VenueDescription Itinerary'>
                        <b>{lang.includes("es") ? "Cóctel" : "Cocktail"}<br /></b>
                        {lang.includes("es") ? "TBD" : "TBD"}<br />
                    </div>
                </Grid>
                <Grid xs={4}>
                    <LocalBarIcon style={{ fontSize: "9vh" }} />
                </Grid>
            </Grid>
            <Divider variant='middle' style={{ borderWidth: 2, borderColor: 'black' }} />
            <Grid container justifyContent="center" alignItems="center" style={{ padding: 30 }}>
                <Grid xs={8} className='VenueDescription Itinerary'>
                    <b>{lang.includes("es") ? "Cena" : "Dinner"}</b> <br />
                    {lang.includes("es") ? "TBD" : "TBD"}<br />
                </Grid>
                <Grid xs={4}>
                    <FlatwareIcon style={{ fontSize: "9vh" }} />
                </Grid>
            </Grid>
        </div >
    )
}

export default function VenueComponent(props: any): JSX.Element {
    return (
        <div className='VenueComponent'>
            <Grid container justifyContent="center" alignItems="stretch">
                <Grid xs={12} sm={6}>
                    <Itinerary />
                </Grid>

                <Grid xs={12} sm={6}>
                    <CarouselProvider
                        naturalSlideWidth={4}
                        naturalSlideHeight={3}
                        totalSlides={6}
                        isPlaying={true}
                        infinite={true}
                    >
                        <Slider>
                            <Slide index={0}><CarouselCard img={VenuePhotoURL5} /></Slide>
                            <Slide index={1}><CarouselCard img={VenuePhotoURL3} /></Slide>
                            <Slide index={2}><CarouselCard img={VenuePhotoURL4} /></Slide>
                            <Slide index={3}><CarouselCard img={VenuePhotoURL2} /></Slide>
                            <Slide index={4}><CarouselCard img={VenuePhotoURL6} /></Slide>
                            <Slide index={5}><CarouselCard img={VenuePhotoURL1} /></Slide>
                        </Slider>
                    </CarouselProvider>
                </Grid>
            </Grid >
            <p className='VenueDescription'>
                <b>Hacienda Vista Hermosa</b><br />
                Km. 7 Carretera Alpuyeca-Tequesquitengo<br />San José Vista Hermosa<br />Tequesquitengo, Morelos, México<br />

            </p>
        </div>
    )
}

export function SaTDaVenue(props: any): JSX.Element {
    return (
        <div className='VenueComponent'>
            <p className='VenueDescription'>
                <b className='VenueTitle'>Hacienda Vista Hermosa</b><br />
                Km. 7 Carretera Alpuyeca-Tequesquitengo<br />San José Vista Hermosa<br />Tequesquitengo, Morelos, México<br />

            </p>
            <Grid container justifyContent="center" alignItems="stretch">
                <Grid xs={1} sm={2}></Grid>
                <Grid xs={10} sm={8}>
                    <div style={{ textAlign: 'center' }}>
                        <CarouselProvider
                            naturalSlideWidth={4}
                            naturalSlideHeight={3}
                            totalSlides={6}
                            isPlaying={true}
                            infinite={true}
                        >
                            <Slider>
                                <Slide index={0}><CarouselCard img={VenuePhotoURL5} /></Slide>
                                <Slide index={1}><CarouselCard img={VenuePhotoURL3} /></Slide>
                                <Slide index={2}><CarouselCard img={VenuePhotoURL4} /></Slide>
                                <Slide index={3}><CarouselCard img={VenuePhotoURL2} /></Slide>
                                <Slide index={4}><CarouselCard img={VenuePhotoURL6} /></Slide>
                                <Slide index={5}><CarouselCard img={VenuePhotoURL1} /></Slide>
                            </Slider>
                        </CarouselProvider>
                    </div>
                </Grid>
                <Grid xs={1} sm={2}></Grid>
                <Grid xs={12}>
                    <div>
                        <a href={HaciendaVistahermosa.mapURLWaze} target="_blank" rel="noreferrer"><img alt='Waze link' className='mapButtons' src={Waze} /></a>
                        <a href={HaciendaVistahermosa.mapURLGM} target="_blank" rel="noreferrer"><img alt='Google Maps link' className='mapButtons' src={GMaps} /></a>
                    </div>
                </Grid>
            </Grid >

        </div >
    )
}